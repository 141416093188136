.keys-section-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    margin-top: 35px;
    // overflow: hidden;
    .light-bg{
        top: -70%;
        @media all and (max-width:769px) {
            top: -17% !important;
        }
    }
    img {
        width: 80%;
    }
    .keys-section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .keys-section-header {
        position: absolute;
        font-family: "PublicSans";
        top: 30%;
        left: 50%;
        transform: translate(-50%, -70%);
        color: white;
        line-height: 61px;
        font-size: 60px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        z-index: 10;
        font-weight: 700;
        @media only screen and (max-width: 601px) {
            top: 23%;
            font-size: 30px;
            line-height: 35px;
        }
    }
}
