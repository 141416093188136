.Declina{
    .row{
        margin: 0;
    }
    .swiper-pagination {
        bottom: -26px !important;
        width: initial !important;
        left: 65% !important;
        @media all and (max-width:769px) {
            left: 40% !important;
        }
    }
    padding: 21px 0;
    padding-top: 0;
    margin-top: -60px;
    @media all and (max-width:1024px) {
        background-size: cover;
        background-position: center;
        margin-top: 0;
    }
    .content{
        @media all and  (min-width:1250px) {
            padding: 72px;
            width: 620px;
            padding-top: 110px;
        }
        @media all and (max-width:768px) {
            padding: 25px;
            text-align: center;
            width: auto;
        }
        @media (min-width:769px) and (max-width:1024px) {
            padding-top: 80px;
        }
    }
    .col-lg-5{
        padding: 0;
    }
    @media all and (max-width:769px) {
        .col-12{
            padding: 0;
        }
    }
}