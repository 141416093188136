@import "./Colors.scss";
@import "./Fonts.scss";
@import "./Mixin.scss";

/*  Global Styles   */
.flexCenterCol {
  @include flexCenter(column);
}

.flexCenterRow {
  @include flexCenter(row);
}
.section-container {
  padding: 60px 0;
}
// style global title
.title {
  font-size: 70px;
  font-weight: 700;
  font-family: "PublicSans";
  margin-bottom: 10px;
  color: $White;
  -webkit-text-stroke: 0.2px white;
  text-transform: uppercase;
  @media all and (max-width: 769px) {
    font-size: 35px;
    padding: 0 25px;
  }
  @media only screen and (max-width:992px) {
    font-size: 35px;

  }
}
.subtitle {
  font-size: 40px;
  line-height: 47px;
  font-weight: 700;
  margin-bottom: 20px;
  -webkit-text-stroke: 0.2px white;
  font-family: "PublicSans";
  color: $White;
  margin: 15px 0;
  @media all and (max-width: 992px) {
    font-size: 26px;
    line-height: 30.5px;
  }
}
// style pargarph
.paragraph {
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: $White;
  font-family: "OpenSans";
   strong {
    font-weight: 700;
    font-family: "OpenSansRegular";
  }
  span {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 769px) {
    padding: 20px 0;
  }
}
// style caption
.caption {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
  color: $Nuovo;
  font-family: "PublicSans";
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;

  @media all and (max-width: 769px) {
    font-size: 12px;
    line-height: 14px;
  }
}
// when user click on any submit button will show this style
.Done-Submit{
  background-color: $BlackDefault !important;
  color: $AccentDefault !important;
  transition: all .4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $BlackDefault;
  &:hover{
    border: 1px solid $BlackDefault !important;
  }
  img{
      margin-right: 5px;
  }
}
// some spaces
.pad-Left {
  padding-left: 120px;
  @media all and (max-width: 769px) {
    padding-left: 0;
  }
}
// padding on mobile
.padding-mobile {
  @media all and (max-width: 769px) {
    padding: 0 20px;
  }
}
// light backgrounds
.light-bg {
  position: absolute;
  top: -24%;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    width: 100%;
    @media all and (max-width: 769px) {
      width: 100% !important;
      height: 100%;
    }
  }
  @media all and (max-width:769px) {
    top: -33% !important;
    img{
      object-fit: cover;
    }
  }
}
// style global swiper js
.swiper-pagination {
  // margin-top: 50px !important ;
  .swiper-pagination-bullet {
    background-color: $White !important;
    opacity: 1 !important;
    transition: all 0.3s ease-in-out;
    // margin: 0 2px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: $AccentDefault !important;
    width: 50px !important;
    border-radius: 7px !important;
    transition: all 0.3s ease-in-out;
  }
}

.button-default {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 19px;
  padding: 15px;
  text-transform: uppercase;
  font-family: "OpenSansBold";
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s;
}

.outlined-button {
  @extend .button-default;
  border-color: $AccentDefault;
  background-color: transparent;
  color: $AccentDefault;

  &:hover {
    background-color: $AccentDefault;
    color: $BlackDefault;
  }
}

.filled-button {
  @extend .button-default;
  background-color: $AccentDefault;
  color: $BlackDefault;

  &:hover {
    background-color: transparent;
    border-color: $AccentDefault;
    color: $AccentDefault;
  }
}

.form-group {
  display: flex;
  flex-direction: column-reverse;
}

.title-sections-secondary {
  @include text(60px, 102%, PublicSans, $White, uppercase,700);
  -webkit-text-stroke: 0.2px white;
  @media only screen and (max-width: 769px) {
    @include text(30px, 35px, PublicSans, $White, uppercase, 700 , center);
  }
}

.paragraph-section {
  @include text(16px, 145%, OpenSans, $White);
  @media only screen and (max-width: 769px) {
    line-height: 23px;
    text-align: center;
  }
  strong {
    font-family: "OpenSansRegular";
    font-weight: 700;
  }
}

.title-sections-primary {
  @include text(70px, 110%, PublicSans, $White, uppercase,700);
  -webkit-text-stroke: 0.2px white;
  @media only screen and (max-width: 769px) {
    @include text(35px, 41px, PublicSans, $White, uppercase, 700, center);

  }
}

.padding-right-200 {
  padding-right: 200px;
  @media only screen and (max-width: 769px) {
    padding-right: 0px !important ;
  }
}
@keyframes example {
  0% {
    transform: translateX(1px);
  }
  40% {
    transform: translateY(250px);
  }
  100% {
    transform: translateY(1px);
  }
}

.fadeInUp {
  animation-name: example;
  animation-delay: 10ms;
  animation-duration: 2.2s;
}

// cutome fade in up
@keyframes CustomefadeInUp {
  from {
      transform: translate3d(0,10px,0);
  }
  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}