@import "./../../Assets/GlobalStyles/GlobalStyles.scss";

.contact-section-wrapper {
    max-width: 850px;
    margin: 48px auto 105px;

    .caption {
        width: 100%;
        text-align: center;
    }
    .paragraph-section{
        @media all and (max-width:769px) {
            padding: 0 2px;
        }
    }
    .form-container {
        font-family: "OpenSans";
        max-width: 550px;
        margin: 0 auto;
        @media only screen and (max-width: 601px) {
            padding: 0px;
        }

        .invalid {
            input {
                border: 1px solid $RedError;
            }

            input:focus {
                border: 1px solid $AccentDefault;
                outline: none;
            }

            input:focus + label {
                color: $AccentDefault;
            }
        }

        .invalid-paragraph {
            font-size: 11px;
            line-height: 15px;
            font-weight: 600;
            color: $RedError;
            margin-top: -12px;
        }
        .form-message {
            display: flex;
            background-color: $PrimaryDefault;
            padding: 10px 67px;
            color: $White;
            font-family: "OpenSans";
            margin: 45px 0 15px;
            @media only screen and (max-width: 426px) {
                padding: 10px;
                margin: 30px 0;
            }
            p {
                margin-left: 20px;
                margin-bottom: 0;
            }
        }

        input {
            max-height: 48px;
            display: inline-block;
            background-color: transparent !important;
            background: none;
            width: 100%;
            padding: 17px 14px;
            border: 1px solid $White;
            margin-bottom: 15px;
            color: $White;

            &:focus {
                border: 1px solid $AccentDefault;
                outline: none;
            }
            &:focus + label {
                color: $AccentDefault;
            }
        }

        textarea {
            width: 100%;
            padding: 14px;
            background: none;
            border: 1px solid $White;
            margin-bottom: 15px;
            color: $White;
            font-family: "OpenSans";

            &:focus {
                border: 1px solid $AccentDefault;
                outline: none;
            }
            &:focus + label {
                color: $AccentDefault;
            }
        }

        .form-label {
            display: block;
            font-size: 11px;
            line-height: 15px;
            font-family: "OpenSans";
            font-weight: 600;
            padding-bottom: 5px;
            color: $White;
            text-transform: uppercase;

            span {
                text-transform: lowercase;
            }
        }

        .document-paragraph {
            font-size: 14px;
            line-height: 22px;
            margin-top: 0;
            font-family: "OpenSans";

            strong {
                font-weight: 700;
                font-family: "OpenSansRegular";
            }
        }
        .checkbox-container {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            .hidden-checkbox {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: 1px !important;
            }
            .icon {
                fill: none;
                stroke: $AccentDefault;
                stroke-width: 3px;
                vertical-align: baseline;
                @media only screen and (max-width: 426px) {
                    margin-top: -5px;
                }
            }
            .checked {
                border: 1px solid $AccentDefault !important;
            }

            .styled-checkbox {
                display: inline-block;
                width: 25px;
                height: 20px;
                margin-right: 15px;
                cursor: pointer;
                background: none;
                @media only screen and (max-width: 426px) {
                    width: 35px;
                }
                border: 1px solid $White;
                transition: all 150ms;

                &:hover {
                    border-color: $AccentDefault;
                }
            }
            .checkbox-label {
                font-size: 14px;
                line-height: 22px;
                color: $White;
                a {
                    color: $AccentDefault;

                    &:hover {
                        font-weight: 600;
                    }
                }
            }
        }

        .form-action {
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 601px) {
                flex-direction: column-reverse;
            }

            .submit-button {
                font-family: "OpenSansBold";
                width: 100%;
                background: $AccentDefault;
                color: #222222;
                font-size: 14px;
                line-height: 19px;
                font-weight: 700;
                padding: 16px 87px;
                border: 1px solid transparent;
                text-transform: uppercase;
                transition: all .3s ease-in-out;
                @media only screen and (max-width: 601px) {
                    margin-top: 20px;
                }
                &:hover {
                    background: none;
                    border: 1px solid $AccentDefault;
                    color: $AccentDefault;
                    transition: all .3s ease-in-out;

                }
            }
            .information-text {
                align-self: flex-start;
                font-size: 14px;
                line-height: 22px;
                color: $White;
            }
        }

        .privacy-info {
            font-size: 14px;
            line-height: 22px;
            margin: 10px 0;
            color: $White;
            & a {
                color: $AccentDefault;
            }
        }
    }
}
