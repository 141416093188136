/*  all app colors  */
$Nuovo: #3ec9cc;
$White: #ffffff;
$PrimaryDefault: #318a8c;
$PrimaryDark: #2c8183;
$AccentDefault: #dcff00;
$AccentDark: #d6f705;
$BlackDefault: #222222;
$BlackDark: #121212;
$RedError : #FF6161;
