@import "../../Assets/GlobalStyles/Colors.scss";
.Navbar {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px !important;
    z-index: 99;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    text-transform: uppercase;
    @media all and (max-width: 769px) {
        padding: 0 !important;
    }

    .navbar-toggler {
        border: none !important;
    .navbar-toggler-icon {
        background-image: url("./../../Assets/Icons/burger-icon.svg");
      }
    }
    .eye-logo {
        object-fit: contain;
    }
    .navbar-nav {
        margin-left: auto;
        .nav-item {
            display: flex;
            align-items: center;
            padding:15px;
            color: $White ;
            a{
                opacity: .5;
                text-decoration: none;
                font-size: 12px;
                font-family: "PublicSans";
                &:hover {
                    color: $AccentDefault !important;
                    opacity: 1;
                }
            }
        }
        .active {
            color: $White !important;
            opacity: 1 !important;
        }
    }

    .show {
        background-color: rebeccapurple;
        height: calc(100vh - 60px);
        // background: url('../../Assets/Images/background-sito_2.png');
        background-size: cover;
        // background: linear-gradient(45deg, rgba(214,247,5,1) 0%, rgba(44,129,131,1) 28%, rgba(0,0,0,1) 71%, rgba(0,0,0,1) 100%)
        // background: linear-gradient(45deg, #222222 ,#334033 , #242726)
        // background: repeating-linear-gradient(to top left, #242726 22%, #334033 40%, #222222 72%);
        // background-image: linear-gradient(to right top, #242726, #262c29, #28312c, #2b352f, #2e3a31, #2c3a33, #2a3934, #283936, #263335, #262d30, #252729, #222222);
        background-image: linear-gradient(
            to left top,
            #242726,
            #272f2c,
            #2b3631,
            #2f3e35,
            #344639,
            #31463c,
            #2e463e,
            #2b4641,
            #273c3f,
            #283338,
            #272a2e,
            #222222
        );
    }
}
.linear {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 70%, rgba(0, 0, 0, 0) 100%) !important;
}
