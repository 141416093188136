@import '../../Assets/GlobalStyles/Colors.scss';
@import '../../Assets/GlobalStyles/Mixin.scss';
.MobileSlider{
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
    .mobileSiwper{
        overflow: visible !important;
        height: 600px ;
        img{
            @include PosCenter;
            object-fit: contain;
            @media all and (max-width:992px) {
                @include PosCenter;
                object-fit: contain;
            }
            @media only screen and  (min-width:1210px) {
                right: 13px;
            }
        }
    }
    .mobileframe{
        position: absolute;
        top: 47%;
        left: 0;
        transform: translateY(-50%);
        z-index: 20;
        pointer-events: none;
        @media all and (max-width:992px) {
            @include PosCenter;
            top: 47%;
            z-index: 20;
        }
    }

$breakpoints: (
    'sm': 630px,
    'md': 768px,
    'lg': 1024px,
);
    .swiper{
        @media (min-width: map-get($breakpoints, 'lg')) {
            padding-left: 5px;
        }
    }
    .swiper-wrapper {
        // enough width to have off screen slides partially visible     
        width: 73.8%;
        will-change: transform;
        
        @media (min-width: map-get($breakpoints, 'sm')) {
            width: 100%;
        }

    }
    
    .swiper-slide {
        width: 100%;
        overflow: hidden;
        width: 270px !important;
        &.swiper-slide-active,
        &.swiper-slide-duplicate-active {
            .slide-image {
                transform: scale3d(1, 1, 1);
            }
        }
        
        @media (min-width: map-get($breakpoints, 'sm')) {
            width: 50%;
        }
    
        @media (min-width: map-get($breakpoints, 'md')) {
            width: 33.333333%;
            
            &.swiper-slide-next,
            &.swiper-slide-prev,
            &.swiper-slide-duplicate-next,
            &.swiper-slide-duplicate-prev {
                .slide-image {
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    
        @media (min-width: map-get($breakpoints, 'lg')) {
            width: 33.333333%;

        }
    }
    


}
.MobileSlider-rtl{
    padding-right: 0;
    padding-left: 80px;
    @media all and (max-width:1368px) {
        padding: 0;
        padding-bottom: 30px;
    }
    img{
        transform: translate(0%,-50%) !important;
        @media all and (max-width:768px) {
            transform: translate(-50%,-50%) !important;
        }
        @media (min-width:769px) and (max-width:1024px) {
            left: 8px !important;
        }
    }

    .mobileframe{
        position: absolute;
        top: 47%;
        right: 0;
        @media all and (max-width:769px) {
            @include PosCenter;
            top: 47%;
            right: auto;
            z-index: 2;
            // left: 59%;
        }
    }
}