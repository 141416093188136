@import "../../Assets/GlobalStyles/Mixin.scss";
@import "../../Assets/GlobalStyles/Colors.scss";
.Creativita {
    .row{
        margin: 0;
    }
    position: relative;
    .light-bg-Two {
        top: 80% !important;
    }
    .swiper-pagination {
        bottom: -26px !important;
        width: initial !important;
        left: 16% !important;
        @media all and (max-width:769px) {
            left: 40% !important;
            bottom: -26px !important;
        }
    }
    padding: 30px 0;
    padding-bottom: 0;
    .header {
        .title {
            font-size: 60px;
            line-height: 61px;
            @media all and (max-width: 769px) {
                font-size: 30px;
                line-height: 35px;
            }
        }
        @media all and (max-width: 992px) {
            @include flexCenter(column);
            text-align: center;
            p {
                padding: 20px;
            }
        }
        @media (min-width: 770px) and (max-width: 992px) {
            text-align: left;
        }
    }
    .content {
        padding: 90px 10px 20px 110px;

        @media all and (max-width: 992px) {
            padding: 0;
            @include flexCenter(column);
            text-align: center;
            p {
                padding: 20px;
            }
        }
        @media (min-width:993px) and (max-width:1280px) {
            padding: 90px 10px 20px 30px;
        }
        @media (min-width: 770px) and (max-width: 992px) {
            text-align: left;
        }
        @media (min-width: 992px) and (max-width: 1200px) {
            padding: 0;
        }
    }
    .DownApp {
        display: flex;
        align-items: center;
        padding-left: 110px;
        @media all and (max-width:1280px) {
            padding-left: 30px;
        }
        span {
            color: $Nuovo;
            text-transform: uppercase;
            &:nth-of-type(1) {
                font-size: 70px;
                margin-right: 20px;
                font-family: "PublicSans";
                font-weight: bold;
            }
        }
        @media all and (max-width: 769px) {
            padding: 10px;
            flex-direction: column;
            text-align: center;
            border: 1px solid $Nuovo;
            margin: 0 20px;
            .paragraph {
                margin-top: 10px;
                padding: 0px !important;
            }
            span {
                font-size: 14px;
                line-height: 22px;
                &:nth-of-type(1) {
                    font-size: 50px;
                    line-height: 51px;
                    margin-right: 0px;
                }
            }
        }
        @media (min-width: 992px) and (max-width: 1200px) {
            padding: 0;
        }
    }
    .col-lg-6{
        padding: 0;
    }
    @media all and (max-width:769px) {
        .col-12{
            padding: 0;
        }
    }
    .MobileSlider{
        margin-top: -90px;
        @media all and (max-width:1280px) {
            margin-top: 0;
        }
    } 
}
