/* Global mixins will be here  */

// center using flex
@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

//center using position
@mixin PosCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin text($fontSize, $lineHeight, $fontFamily, $color, $textTransform: none, $font-weight: 400, $text-align: left) {
    text-align: $text-align;
    letter-spacing: 0.5px;
    font-weight: $font-weight;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-family: $fontFamily;
    color: $color;
    text-transform: $textTransform;
}
