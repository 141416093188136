@import '../../../Assets/GlobalStyles/Colors.scss';
@import '../../../Assets/GlobalStyles/Mixin.scss';
.QuizSlider{
    overflow: hidden;
    padding-bottom: 30px;
    .swiper{
        @include flexCenter(column);
        overflow: visible;
        .swiper-slide{
            width: 504px !important;
            opacity: .5;
            transition: translate .3s ease-in-out;
            @media  only screen and (max-width: 769px){
                width: 100% !important;
            }
        }
        .swiper-slide-active{
            opacity: 1;
            transition: translate .3s ease-in-out;
        }
        .swiper-button-disabled{
            opacity: 0;
        }
        .swiper-slide-prev{
            opacity: 0;
        }
        .swiper-pagination{
            // bottom: 17%;
            bottom: -30px;
            left: 0px;
        }
    }

    .question{
        background-color: $BlackDefault;
        border: 1px solid $AccentDefault;
        padding: 25px;
        height: 400px;
      
        @include flexCenter(column);
        text-align: center !important;
        margin-bottom: 50px;
        position: relative;
        .lockIcon{
            position: absolute;
            left: 30px;
            top: 30px;
        }
        .caption{
            margin-bottom: 0;
            img{
                margin-right: 10px
            }
        }
        .paragraph{
            @include text(18px , 21.11px , "PublicSansBold" , $White);
            text-align: center !important;
            margin: 20px 0;
            padding: 0;
            @media all and (max-width:769px) {
                font-size: 16px;
            }
        }
        .choices{
            width: 100%;
            .choice{
                background-color: $AccentDefault;
                margin: 10px 0;
                text-align: center !important;
                padding: 20px 10px;
                height: 50px;
                @include flexCenter(row);
                width: 100%;
                @include text(14px , 22.4px , "OpenSansRegular" , $BlackDefault);
                font-weight: 700;
                @media all and (max-width:769px) {
                    height: auto;
                    padding: 12px 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-family: 'OpenSansBold', sans-serif;
                }
            }
            // selected choice 
            .not-selected{
                background-color: transparent;
                color: $AccentDefault;
                border: 1px solid $AccentDefault;
                font-weight: normal;
            }
            .selected{
                background-color: $PrimaryDark;
                color: $White;
                font-weight: bold;
                border: 1px solid $PrimaryDark;
            }
        }
        @media all and (max-width:769px) {
            height: auto;
        }
    }

    .Result{
        background-color: $PrimaryDark;
        color: $White;
        padding: 15px;
        opacity: 0;
        transition: all .3s ease-in-out;
        height: 540px;
        @media all and (max-width:769px) {
            height: auto;
        }
        .head{
            @include flexCenter(column);
            background-color: $White;
            color: $BlackDark;
            text-align: center;
            padding: 10px;
            display: block !important;
            font-family:'PublicSans';

            h2{
                font-size: 18px;
                font-weight: bold;
            }
            p{
                @include text(14px , 22.4px , 'OpenSansRegular' , $BlackDefault );
                text-align: center !important;
            }
        }
        .paragraph{
            font-size: 15px;
            margin: 15px 0;
            text-align: center;
        }
        .Email{
            label{font-size: 11px;}
            .errEmail{
                border: 1px solid $RedError;
            }
            .invalid-email{
                color: $RedError;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
            }
            input{
                display: block;
                width: 100%;
                padding: 8px 15px;
                border: 1px solid $White;
                background-color: transparent;
                outline: none;
                color: $White;
                font-size: 14px;
                height: 48px;
                &::placeholder{
                    color: $White;
                }
            }
        }
    

        .checkbox-container {
            display: flex;
            align-items: center;
            .hidden-checkbox {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: 1px !important;
            }
            .icon {
                fill: none;
                stroke: $AccentDefault;
                stroke-width: 3px;
                vertical-align: baseline;
                width: 100%;
                @media only screen and (max-width: 426px) {
                    margin-top: -5px;
                }
            }

            .styled-checkbox {
                @include flexCenter(row);
                width: 24px ;
                height: 20px;
                margin-right: 15px;
                background: none;
                border: 1px solid $White;
                transition: all 150ms;
                &:hover {
                    border-color: $AccentDefault;
                }
                @media all and (max-width:769px) {
                    width: 37px;
                }
            }
            .checkbox-label {
                font-size: 12px;
                line-height: 16px;
                margin: 25px 0;
                color: $White;
                font-family: 'OpenSansRegular';

                a {
                  color: $AccentDefault;
                }
            }
        }
        .submit-button{
            background-color: $AccentDefault;
            color: $BlackDark;
            padding: 15px;
            border: none;
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
            transition: all .4s ease-in-out;
            border: 1px solid transparent;
            &:hover{
                background-color: transparent;
                border: 1px solid $AccentDefault;
                color: $AccentDefault;
            }
        }

        .info{
            font-size: 12px;
            margin-top: 10px;
            font-family:'PublicSans';
            a{
                color: $AccentDefault;
            }
        }
    }
    .showResult{
        opacity: 1;
        transition: all .3s ease-in-out;
        animation: RightTOLeft 1s ease-in-out ;
        @keyframes RightTOLeft {
            from { 
                transform : translateX(3em);
                opacity: 0;
            }
            to{
                transform : translateX(0em);
                opacity: 1;
            }
        }
    }
    .ResetQuiz{
        margin-top: 30px;
        span{
            @include flexCenter(row);
            @include text(13px , 10px , 'OpenSansRegular' , $AccentDefault );
            font-weight: 700;
            text-transform: uppercase;
            img{
                margin-left: 8px;
            }
        }
    }
    // style navigations button 
    .slider-arrows{
        display: flex;
        justify-content: space-between;
        width: 500px;
        // margin-bottom: 10px;
        z-index: 99;
        position: absolute;
        // bottom: 24%;
        bottom: 0;
        // left: 160px;
        // left: 205px;
        left: 151px;
        @media all and (max-width:769px) {
            width: 100%;
            left: 0px;
        }
        .arrow{
            color: $AccentDefault;
            @include text(14px , 19px , "OpenSansRegular" , $AccentDefault);
            text-transform: uppercase;
            font-weight: 700;
            &:nth-of-type(1){
                img{margin-right: 10px;}
            }
            &:nth-of-type(2){
                img{margin-left: 10px;}
            }
        }
    }
}