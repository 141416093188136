@import '../../Assets/GlobalStyles/Colors.scss';
@import '../../Assets/GlobalStyles/Mixin.scss';
.Header{
    background:url('../../Assets/Images/background/background.Dark.png');
    background-size: cover;
    color: $White;
    padding: 156px 0 134px 0;
    @media all and (max-width:769px) {
        padding: 116px 0 60px 0;
    }
    .Video{
        position: relative;
        @include flexCenter(column);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px !important;
        height: 400px !important;
        position: relative;
        img{
            &:nth-of-type(2){
                @include PosCenter
            }
        }
        iframe{
            @include PosCenter;
            width: 100%;
            height: 100%;
            z-index: 9;
        }
        .overlay{
            background-color:transparent;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
        }
    }
    .Content{
        padding: 0 83px;
        @media all and (max-width:768px) {
            padding: 0 !important;
            @include flexCenter(column);
            text-align: center;
        }
        @media all and (max-width:768px) {
            img {
                margin-bottom: 20px;
            }

            .paragraph {
                padding-bottom: 0px;
            
            }
            .title {
                margin-bottom: 0px;
            }
        }
        @media (min-width:769px) and (max-width:1024px) {
            padding-right: 0;
        }
    }
}