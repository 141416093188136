@import "./../../Assets/GlobalStyles/GlobalStyles.scss";
@import "../../Assets/GlobalStyles/Mixin.scss";

.creativity-section {
    position: relative;
    .light-bg {
        top: -7%;
    }
    .light-bgTwo {
        top: 52%;
    }
    @media only screen and (max-width: 769px) {
        text-align: center;
        margin-bottom: 150px !important;
    }
    .bg-img {
        @include PosCenter;
    }
    .client-creativity-header {
        .title-sections-secondary{
            // @media all and (max-width:601px) {
            //     padding: 0 20px;
            // }
        }
        .paragraph-section {
            max-width: 654px;
            @media all and (max-width:766px) {
                max-width: 332px;
                margin:0 auto;
            }
        }
        padding-right: 150px;
        @media only screen and (max-width: 1400px) {
            padding-right: 0 !important;
        }
        h2 {
            @media only screen and (min-width: 1200px) {
                width: 800px;
            }
        }
    }
    .container-creativity {
        overflow: hidden;
        padding-top: 57px;
        @media all and (max-width:601px) {
            padding-top: 0;
        }
    }
    .client-creativity-phone-wrapper {

        display: flex;
        padding: 0px 35px 0px 216px;
        margin-top: -10px;
        @media only screen and (max-width: 1400px) {
            padding: 0px 35px 0px 165px;
            margin-top: 0px;
        }
        @media only screen and (max-width: 769px) {
            margin: 0 auto !important;
            flex-direction: column !important;
            justify-content: center !important;
            padding: 0px;
        }
        @media (min-width:769px) and (max-width:1024px) {
            padding: 0;
            margin: 0;
            margin-top: 80px;
        }

        .client-creativity-phone-wrapper-content {
            display: flex;

            flex-direction: column;
            justify-content: center;
            margin-top: -80px;
            padding-top: 110px;
            @media only screen and (max-width: 769px) {
                margin: 0 auto;
                align-items: center;
                 padding: 20px 0;
            }

            .phones-section-title {
                font-weight: bold;
                font-size: 40px;
                line-height: 47px;
                text-transform: capitalize;
                font-family: "PublicSans";
                color: $White;
                @media all and (max-width: 769px) {
                    font-size: 26px;
                    line-height: 30px;
                }
            }

            .paragraph-section {
                max-width: 497px;
                @media all and (max-width: 766px) {
                    max-width: 332px;
                }
                @media (min-width:768px) and (max-width:1024px) {
                    max-width: 100%;
                }
            }
        }

        .client-creativity-phone-wrapper-image {
            background: url("./../../Assets/Images/MobileSlider/mobileFrame.svg");
            background-size: contain;
            background-repeat: no-repeat;
            min-width: 280px;
            min-height: 562px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -55px;
            @media only screen and (max-width: 1400px) {
                width: 280px;
                height: 562px;
            }
            @media only screen and (max-width: 769px) {
                margin: 0 auto;
            }
            img {
                width: 88%;
                margin-bottom: 13px;
            }
        }
    }

    .margin-first-phone {
        margin: -71px 70px 0px 0px ;
        margin-top: -200px !important;
        @media all and (max-width:769px) {
            margin-top: 0 !important;
        }

    }
    .second-phone-wrapper {
        @media only screen and (min-width: 769px) {
            float: left;
        }

        flex-direction: row-reverse;
        padding: 0px 235px 0px 35px;
        @media all and (max-width:1024px) {
            padding: 0;
        }
        // margin-top: -40px;
    }
    .margin-second-phone {
        margin: 0px 0px 0px 90px;
    }
    .color-3EC9CC {
        color: $Nuovo;
    }

    .views-container {
        display: flex;
        align-items: center;
        margin-top: 40px;
        @media only screen and (max-width: 1400px) {
            margin-top: 5px;
        }
        @media only screen and (max-width: 769px) {
            // width: 332px;
            height: 103px;
            flex-direction: column;
            align-items: center;
            border: 1px solid $Nuovo;
            padding: 10px;

            margin: 20px auto 25px;

            p {
                font-size: 14px;
                padding-right: 0 !important;
                margin: 0 !important;
            }
            h6 {
                
                font-size: 50px;
                margin: 5px 0 !important;
                text-align: center;
                line-height: 51px;
            }
        }
        h6 {
            margin-right: 20px;
            -webkit-text-stroke: unset;
        }
        p {
            padding-right: 100px;
            &:nth-of-type(1){
                padding: 0;
            }
        }
    }
}
