body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background: url("./Assets/Images/background/background.Dark.png");
}

* {
  cursor: none;
}

button:hover {
  cursor: none !important;
}

/* change fadein right distance */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
 }
 100% {
    opacity: 1;
    transform: translateX(0);
 }
}

#CybotCookiebotDialog{
  z-index: 1000 !important;
}

html {
    overflow-x: hidden !important;
}