@import '../../Assets/GlobalStyles/Colors.scss';
.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 1000;
}

.cursor-dot {
    border-radius: 0%;
    width: 9px;
    height: 25.32px;
    background: url('../../Assets/Icons/cursor.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.cursor-dot-outline {
  width: 70px;
  height: 70px;
  border: 1px solid $AccentDark;
}
