@import "./../../Assets/GlobalStyles/GlobalStyles.scss";
.team {
    @media all and (max-width:601px) {
        padding-top: 0;
    }
    .section-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;

        @media only screen and (max-width: 769px) {
            margin-top: 40px;
        }
        @media only screen and (max-width: 601px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
