@import "./../../Assets/GlobalStyles/GlobalStyles.scss";

// .path-to-growth-section {
//     position: relative;
//     background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
//     &::before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         height: 243px;
//         background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
//         z-index: 100;
//         @media all and (max-width:601px) {
//             background:none ;
//         }
//     }
// }

.path-to-growth-section-wrapper {
    height: 622px;
    padding-top: 160px;
    padding-bottom: 187px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media only screen and (max-width: 769px) {
        align-items: center;
        text-align: center;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 33px;
        height: auto;
    }
    .active {
        border: 1px solid $AccentDefault !important;
        box-shadow: 0px 0px 20px rgba(220, 255, 0, 0.4);
    }
    .fadein{
        animation: CustomefadeInUp .7s linear forwards ;
    }
    .paragraph-section {
        margin-bottom: 30px;
        padding-right: 87px;
        @media all and (max-width:769px) {
            padding: 0 13px;
        }
    }
    .action-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "OpenSans";
        // max-height: 300px;
        overflow: hidden;
        scroll-behavior: smooth;
        padding-left: 30px;
        transition: all .4s ease-in-out;
        margin-bottom: 30px;
        @media all and (max-width: 769px) {
            max-height: 100% !important;
            padding-left:0;
        }
        .text-left{
            @media all and (max-width:769px) {
                text-align: left ;
            }
        }

        .action-section-paragraph {
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 700;
            padding: 12px;
            font-size: 14px;
            line-height: 19px;
            border: 1px solid $White;
            color: $White;
            margin-bottom: 20px;
            background: $BlackDefault;
            text-transform: uppercase;
            transition: all .4s ease-in-out;
            width: 380px;
            &:nth-of-type(1){
                opacity: 1;
            }
            width: 376px;
            .action-section-heading-paragraph {
                display: flex;
                width: 336px;

                @media all and (max-width: 769px) {
                    width: 294px;
                }
            }
        
            .paragraph-text {
                // white-space: break-spaces;
                line-height: 19px;
                display: block;
                font-weight: 400;
                text-align: center;
                text-transform: none;
                width: 352px;

                @media all and (max-width: 769px) {
                    width: 310px;
                }
            }

            .paragraph-text-margin {
                margin-top: 12px;
            }
            img {
                width: 40px;
                height: 40px;
            }

            .margin-left-20 {
                margin-left: 20px;
            }
            p {
                margin-bottom: 0;
                .text-align-normal {
                    text-align: left !important;
                    margin-top: 12px;
                }
            }
            span {
                display: inline-block;
            }
            position: relative;
            &:nth-of-type(1) {
                &::before {
                    display: none;
                }
            }
            &::before {
                content: "";
                width: 20px;
                height: 0px;
                border: 1px solid $White;
                background-color: white;
                position: absolute;
                // top: -17%;
                top: -12px;
                left: 47%;
                transform: rotate(90deg);
            }
            @media all and (max-width:769px) {
                width: 334px;
                height: 106px;
                @include flexCenter(column)
            }
        }
        .false{
            &:nth-last-of-type(4){
                opacity: .3;
            }
            &:nth-last-of-type(3){
                opacity: .6;
            }
            &:nth-last-of-type(2){
                opacity: .8;
            }
            @media all and (max-width:769px) {
                opacity: 1 !important;
            }
        }
        div:first-child {
            font-weight: 600;
            text-align: center;
            padding: 20px 30px;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .continue-button {
        font-family: "OpenSans";
        font-weight: 700;
        font-size: 13px;
        line-height: 10px;
        color: $AccentDefault;
        background: transparent;
        border: none;
        img {
            margin-left: 5px;
            width: 15px;
            height: 15px;
        }
    }
    .restDalla{
        color: $AccentDefault;
        font-size: 13px;
        font-family:'OpenSansRegular';
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .5px;
    }
}
