@import "../../Assets/GlobalStyles/Colors.scss";

.Hero {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all .3s ease-in-out;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: url("../../Assets/Images/background-sito_3.jpg");
    background-size: cover;
    width: 100%;
    min-height: -webkit-fill-available;
    min-height: 100%;
    @media all and (max-width:601px) {
        background: url("../../Assets/Images/background-sito_2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .logo {
        max-width: 320px;
        @media all and (max-width: 769px) {
            max-width: 235px;
        }
        opacity: 0;
        animation: CustomefadeInUp .7s linear forwards ;
    }
    .middle {
        width: 550px;
        text-align: center;
        opacity: 0;
        animation: CustomefadeInUp .7s linear forwards ;
        animation-delay: .2s;
        img {
            max-width: 100%;
            object-fit: contain;
            display: block;
            @media all and (max-width: 769px) {
                width: 300px;
                height: 380px;
                object-fit: contain;
                margin: 0 auto;
              
            }
        }
        h1 {
            color: $White;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 80px;
            line-height: 68px;
            text-align: center;
            letter-spacing: 5px;
            @media all and (max-width: 769px) {
                font-size: 47px;
                line-height: 45px;
                letter-spacing: 3px;
            }
        }
        p {
            color: $White;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 2px;
            margin-top: 10px;
        }
    }
    .last {
        color: $AccentDefault;
        text-align: center;
        opacity: 0;
        animation: CustomefadeInUp .7s linear forwards ;
        animation-delay: .4s;
        p {
            font-size: 14px;
            font-family:'PublicSans';
            letter-spacing: 2px;
            line-height: 16px;
            padding: 0 40px;
            letter-spacing: 0.5px;
            font-weight: 600;
            text-transform: uppercase;
            @media all and (max-width:769px) {
                padding: 0 70px;
            }
        }
    }
}
.hideHero{
    transition: all .5s ease-in-out;
    animation:animateTotop .5s ease-in-out ;
    @keyframes animateTotop {
        from{
            top: 0;
        }
        to{
            top: -100%;
        }
    }
}