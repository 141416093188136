.Quiz{
    position: relative;
    padding-top:120px;
    @media all and (max-width:769px) {
        padding-top: 50px!important;
        padding-bottom: 52px;
    }
    .light-bg{
        top: -70%;
    }
    .content{
        .caption {
            img {
                margin-right: 16px;
            }

        }
        .paragraph{
            @media all and (max-width:769px) {
                padding: 15px 0;
                margin: 0;
                padding-bottom: 20px;
            }
        }
        .subtitle{
            text-transform: uppercase;
            width: 500px;
            @media all and (max-width:769px) {
                width: auto;
                margin: 0;
            }
        }
        // padding-left: 110px;
        @media all and (max-width:769px) {
            padding: 0;
            text-align: center;
        }
    }
}