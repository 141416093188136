@import "./../../Assets/GlobalStyles/GlobalStyles.scss";
footer {
  background: $PrimaryDark;
  width: 100%;
  font-family: OpenSans;
  padding-top: 110px;
  padding-bottom: 45px;
    img {
      max-width: 320px;
    }

  @media only screen and (max-width: 769px) {
    padding: 30px 20px !important;
    img {
      max-width: 240px;
    }
  }
  .markChecked{
    width: auto !important;
  }
  a {
    color: $AccentDefault;
    font-size: 12px;
    line-height: 16px;
    &:hover {
      color: $AccentDefault;
      font-weight: 600;
    }
  }

  .footer_top-content-container a button {
    width: 151px !important;
    @media all and (max-width:601px) {
      width: auto !important;
    }
  }
  .footer__top-content {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    @media only screen and (max-width: 601px) {
      flex-direction: column;

      .footer__top-content-container {
        width: 100% !important;
      }
    }
    .footer__form-container {
      max-width: 376px;
      @media only screen and (max-width: 601px) {
        width: 100%;
      }
      input {
        max-height: 48px;
        display: inline-block;
        background: none;
        width: 100%;
        padding: 14px;
        border: 1px solid $White;
        margin-bottom: 15px;
        color: $White;
      }
      input::placeholder {
        color: $White;
      }
      input:focus {
        outline: none !important;
        border: 1px solid $AccentDefault;
      }
      input:focus + label {
        color: $AccentDefault;
      }
      .form-paragraph {
        font-size: 12px;
        line-height: 16px;
      }
      button {
        width: 100%;
      }
    }
  }

  .footer__bottom-content {
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
    @media only screen and (max-width: 601px) {
      flex-direction: column-reverse;
      margin-top: 70px;
    }
  }

  .footer__contact-information {
    p {
      color: $White;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 10px;
      @media only screen and (max-width: 601px) {
        line-height: 19px;
      }
    }
  }
  .form-group {
    @extend .form-group;
    width: 100%;
  }

  .footer__paragraph {
    font-family: "OpenSans";
    font-size: 16px;
    line-height: 23px;
    color: $White;
    margin-bottom: 30px;
  }
  .footer__social-icons {
    margin-top: 50px;
    display: flex;
    @media only screen and (max-width: 601px) {
      margin-bottom: 50px;
    }
    a {
      padding-right: 35px;
    }
  }
  .form-label {
    display: block;
    font-size: 11px;
    line-height: 15px;
    font-family: "OpenSans";
    font-weight: 600;
    padding-bottom: 5px;
    color: $White;
    text-transform: uppercase;
  }

  .footer__heading {
    letter-spacing: 1.5px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    color: $White;
    font-family: "PublicSans";
    margin-bottom: 20px;
  }

  .footer__logo {
    align-self: flex-start;
    @media only screen and (max-width: 601px) {
      margin-bottom: 70px;
    }

    width: 295px;

    img {
      width: 100%;
    }
  }
  .footer__navigation-links {
    @media only screen and (max-width: 601px) {
      flex-direction: column;
      margin-top: 30px;
    }
    display: flex;
    text-align: right;
    justify-content: flex-end;
    margin-top: -22px;
    .webrankingLink {
      color: $White;
      font-size: 12px;
      text-decoration: none;
      @media only screen and (max-width: 601px) {
        margin-top: 30px;

        text-align: left;
      }
    }
    div {
      display: flex;
    }
    div a {
      margin-right: 20px;
    }
  }
}
