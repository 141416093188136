@import '../../Assets/GlobalStyles/Colors.scss';
@import '../../Assets/GlobalStyles/Mixin.scss';
.VideosSlider{
    overflow: hidden;
    height: 520px;
    @media all and (max-width:769px) {
        height: auto;
    }
    img{
        object-fit: cover;
    }

    $breakpoints: (
        'sm': 630px,
        'md': 768px,
        'lg': 1024px,
    );
        .swiper{
            transition: height .5s ease-in-out;
            @media all and (max-width:769px) {
                padding-bottom: 40px;
            }
        }
    .swiper-wrapper {
        // enough width to have off screen slides partially visible     
        width: 73.8%;
        will-change: transform;
        transition: width .4s ease-in-out , height .4s ease-in-out , translate .4s ease-in-out ;
        // transition: all .4s ease-in-out;
        @media (min-width: map-get($breakpoints, 'sm')) {
            width: 100%;
        }
    }
    .overlay{
        background-color:transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
    .swiper-slide {
        // width: 100%;
        width: 260px !important;
        height: 325px !important;
        overflow: hidden;
        transition: width .4s ease-in-out , height .4s ease-in-out , translate .4s ease-in-out ;
        iframe{
            transition: width .4s ease-in-out , height .4s ease-in-out , translate .4s ease-in-out ;
        }
        @media (min-width: map-get($breakpoints, 'sm')) {
            width: 50%;
        }
    
        @media (min-width: map-get($breakpoints, 'md')) {
            width: 33.333333%;
            
            &.swiper-slide-next,
            &.swiper-slide-prev,
            &.swiper-slide-duplicate-next,
            &.swiper-slide-duplicate-prev {
                .slide-image {
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    
        @media (min-width: map-get($breakpoints, 'lg')) {
            width: 25%;
          
        }
    }
        .swiper-slide-active{
        width: 384px !important;
        height: 480px !important;
        transition: width .4s ease-in-out , height .4s ease-in-out , translate .4s ease-in-out ;
        iframe{
            transition: width .4s ease-in-out , height .4s ease-in-out , translate .4s ease-in-out ;
        }
        @media all and (max-width:769px) {
            width: 280px !important;
            height: 350px !important;
        }
    }
}