@import "./../../../Assets/GlobalStyles/GlobalStyles.scss";
.staff-card-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 1;
    align-items: center;
    @media only screen and (max-width: 769px) {
        flex-direction: row;
    }

    .staff-card-image {
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin-bottom: 20px;
        border-radius: 100%;
        @media only screen and (max-width: 769px) {
            width: 100px;
            height: 100px;
        }

        img {
            width: 100%;
        }
    }

    .staff-card-title {
        font-family: PublicSans;
        font-weight: 700;
        font-size: 20px;   
        letter-spacing: 0.5px;
        line-height: 24px;
        color: $White;
        -webkit-text-stroke: 0.2px white;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media only screen and (max-width: 769px) {
            text-align: left;
            margin-left: 20px;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .staff-card-description {
        font-size: 16px;
        line-height: 23px;
        font-family: OpenSans;
        white-space: pre-line;
        color: $White;
        text-align: center;
        @media only screen and (max-width: 769px) {
            text-align: left;

            margin-left: 20px;
        }
    }
}
