@import "../../Assets/GlobalStyles/Colors.scss";
@import "../../Assets/GlobalStyles/Mixin.scss";
.Branding {
    padding: 30px 0;
    position: relative;
    @media all and (max-width: 769px) {
        padding: 30px 0 50px;
    }
    .light-bg {
        position: absolute;
        top: -24%;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        img {
            width: 100%;
            @media all and (max-width: 769px) {
                width: auto;
            }
        }
    }
    .leftImg {
        margin-top: -100px;
        display: flex;
        justify-content: center;
        @media all and (max-width: 769px) {
            display: flex;
            justify-content: center;
            img {
                width: 220px !important;
                object-fit: contain;
                height: 100%;
            }
        }
        @media (min-width: 770px) and (max-width: 992px) {
            display: flex;
            justify-content: center;
            img {
                width: 400px !important;
            }
        }
    }
    .Content {
        @media all and (max-width: 769px) {
            @include flexCenter(column);
            margin-left: 0;
            text-align: center;

            .paragraph {
                padding: 0px;
            }
        }
        p {
            line-height: 26px;
            padding-right: 130px;
            @media all and (max-width: 769px) {
                line-height: 22.4px;
                padding: 0 13px !important;
            }
            &:nth-of-type(2) {
                margin-top: 55px;
                line-height: 28px;
                margin-bottom: 35px;
                text-transform: uppercase;
                padding-right: 180px;
                @media all and (max-width: 769px) {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
          
            @media all and (max-width:769px) {
                padding-right:0;
            }
        }
    }
    .VideosSlider{
        margin-top: -32px;
        @media all and (max-width:1024px) {
            padding-top: 20px;
            margin-top: 0;
        }
    }
}
