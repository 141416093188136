/* all app fonts  */

//Spartan
@font-face {
    font-family:'Spartan';
    src: url('../Fonts/Spartan/Spartan-Regular.ttf');
}
@font-face {
    font-family:'SpartanSemiBold';
    src: url('../Fonts/Spartan/Spartan-SemiBold.ttf');
}
@font-face {
    font-family:'SpartanThin';
    src: url('../Fonts/Spartan/Spartan-Thin.ttf');
}

//Public Sans
@font-face {
    font-family:'PublicSans';
    src: url('../Fonts/Public_Sans/PublicSans-Regular.ttf');
}
@font-face {
    font-family:'PublicSansSemiBold';
    src: url('../Fonts/Public_Sans/PublicSans-SemiBold.ttf');
}
@font-face {
    font-family:'PublicSansBold';
    src: url('../Fonts/Public_Sans/PublicSans-Bold.ttf');
}

//Open Sans
@font-face {
    font-family:'OpenSans';
    // src: url('../Fonts/Open_Sans/OpenSans-Regular.ttf');
    src: url('../Fonts/Open_Sans/OpenSans-Light.ttf');

}
@font-face {
    font-family:'OpenSansRegular';
    src: url('../Fonts/Open_Sans/OpenSans-Regular.ttf');

}
@font-face {
    font-family:'OpenSansLight';
    src: url('../Fonts/Open_Sans/OpenSans-Light.ttf');
}
@font-face {
    font-family:'OpenSansSemiBold';
    src: url('../Fonts/Open_Sans/OpenSans-SemiBold.ttf');
}
@font-face {
    font-family:'OpenSansBold';
    src: url('../Fonts/Open_Sans//OpenSans-Bold.ttf');
}
