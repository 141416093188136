@import '../../Assets/GlobalStyles/Mixin.scss';
.Formula{
    padding: 30px 0;
    padding-top: 95px;
    @media all and (max-width:601px) {
        padding: 0;
    }
    .light-bg{
        top: -18%;
    }
    @media all and (max-width:992px) {
        background-size: cover;
        background-position: center;
    };
    position: relative;
    .bg-img{
        @include PosCenter;
    }
    .leftImg{
        img{
            margin-top: -57px;
            width: 100%;
            @media (min-width:769px) and (max-width:992px) {
                margin-top:0;
                margin: 0;
                width: auto !important; 
            }
            @media all and (max-width:768px) {
                width: 221px;
            }
        }
    }
    .Content{
        p{
            &:nth-of-type(1){
                padding-right: 125px;
                @media all and (max-width:769px) {
                    padding: 0 13px !important;
                }
            }
            &:nth-of-type(2){
                margin-top: 55px;
                margin-bottom: 35px;
                text-transform: uppercase;
                padding-right: 230px;
                @media all and (max-width:769px) {
                    margin-bottom: 20px;
                    margin-top: 20px;
                    padding: 0 13px !important;

                }
            }
        }
        @media all and (max-width:769px) {
            @include flexCenter(column);
            text-align: center;

        }
    }
    .formula-slider-container{
        margin-top: -120px;
        @media (max-width: 1399px) {
            margin-top: 0;
        }
        @media all and (max-width:1280px) {
            margin: 0;
        }
    }
}