@import '../../Assets/GlobalStyles/Mixin.scss';
.mobile-menu-wrapper {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%),
        url("./../../Assets/Images/background-sito_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    z-index: 998;
    .mobile-menu-header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        height: 50px;
        padding: 13px 12px;
        .mobile-icon {
            width: 40px;
            height: 40px;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .mobile-menu-logobox {
            height: 40px;
            width: 110px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .mobile-navigation-menu {
        padding: 90px 45px 0px;
        height: 100%;
        position: relative;
        @media all and (max-width:376px) {
            padding: 30px 45px 0px;
        }
        .mobile-navigation-list {
            padding: 0px;
            display: flex;
            flex-direction: column;
            list-style: none;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            color: white;
            font-family: "PublicSans";
            align-items: center;
            .mobile-navigation-list-item {
                margin-bottom: 70px;
                text-align: center;
                @media all and (max-width:376px) {
                    margin-bottom: 60px;
                }
                a {
                    text-transform: uppercase;
                }
            }
        }
    }

    .mobile-navigation-menu-footer {
        width: 100%;
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        
        &-logo {
            margin: auto;
            width: 200px;
            img {
                max-width: 100%;
            }
        }
    }
}
